<template>
  <div>
    <cbs-object v-if="objectFull"
                :object-full="objectFull"
                domainsid="root"
                @close="closeCard"
                :row-buttons="rowButtons"
                @clickRowButton="onClickRowButton"
    />
    <cbs-data-object v-if="false" objectsid="domain" domainsid="root" @load="onObjectLoad" hide />
    <cbs-card-actions title="DOMAINS" sub-title="List of avaliable domains">
      <b-card-body>
        <cbs-domain v-for="(dom, domidx) in domains" :key="domidx" :domain="dom" @run="onRunDomain" />
      </b-card-body>
    </cbs-card-actions>
    <cbs-debug v-if="isDev()" :context="this" />
  </div>
</template>

<script>
import CbsObject from '@/cubus/components/object/CbsObject.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDomain from '@/cubus/components/domains/CbsDomain.vue'
import CbsCardActions from '@/cubus/components/cbs-card-actions/CbsCardActions.vue'
import {
  BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'CbsDomains',
  components: {
    CbsCardActions,
    CbsDebug,
    CbsDataObject,
    CbsObject,
    CbsDomain,
    BCardBody,
  },
  data() {
    return {
      rowButtons: [
        { icon: 'PlayIcon', title: 'Enter domain', emit: 'enterDomain' },
      ],
      objectFull: null,
      domains: null,
    }
  },
  created() {
    useCubus.setPageTitle('Domains')
    this.init()
  },
  methods: {
    init() {
      this.loadDomains()
    },
    closeCard() {},
    onClickRowButton(btn, row) {
      console.log('clickRowButton', btn, row)
      if (btn.emit === 'enterDomain') {
        this.enterDomain(row)
      }
    },
    enterDomain(row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'changedomain',
          param: {
            domainsid: row.item.sid.value,
          },
        },
      })
        .then(response => {
          console.log('change domain response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.session) {
              console.log('new session', response.data.session)
              useCubus.processLogin(this, response)
            }
          }
        })
        .catch(error => {
          console.log('change domain error', error)
          useCubus.toastError(this, error)
        })
    },
    onRunDomain(row) {
      this.enterDomain2(row)
    },
    enterDomain2(row) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'changedomain',
          param: {
            domainsid: row.sid,
          },
        },
      })
        .then(response => {
          console.log('change domain response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.session) {
              console.log('new session', response.data.session)
              useCubus.processLogin(this, response)
            }
          }
        })
        .catch(error => {
          console.log('change domain error', error)
          useCubus.toastError(this, error)
        })
    },
    onObjectLoad(obj) {
      this.objectFull = obj
    },
    loadDomains() {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'domains',
          param: {},
        },
      })
        .then(response => {
          console.log('domains response', response)
          if (response.data) {
            if (response.data.thread) this.delayDomains(response.data.thread)
            if (response.data.error) this.toast(response.data.error)
          }
        })
        .catch(error => {
          console.log('domains error', error)
        })
    },
    delayDomains(threadName) { setTimeout(() => this.threadDomains(threadName), 500) },
    threadDomains(threadName) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('threadDomains response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result && response.data.thread.result.domains) {
                this.domains = response.data.thread.result.domains
              } else {
                this.delayDomains(threadName)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    isDev() { return useCubus.isDeveloper() },
  },
}
</script>

<style scoped>

</style>
