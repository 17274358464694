import { render, staticRenderFns } from "./CbsDomains.vue?vue&type=template&id=154f3766&scoped=true"
import script from "./CbsDomains.vue?vue&type=script&lang=js"
export * from "./CbsDomains.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154f3766",
  null
  
)

export default component.exports