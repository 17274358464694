<template>
  <div class="mb-1 p-1 bordered rounded border-secondary">
    <b-media vertical-align="center">
      <template #aside>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          class="btn-icon rounded-circle"
          :disabled="isCurrentDomain()"
          @click="onRun"
        >
          <b-avatar variant="primary" :text="initials()"></b-avatar>
        </b-button>
      </template>
        <h5>{{ domain.name }}</h5>
        <small class="text-secondary">
          {{ domain.descr }} ({{ domain.id || 'no_ID' }}, {{ domain.sid || 'no_SID' }})
        </small>
    </b-media>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BMedia,
} from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CbsDomain',
  components: {
    BAvatar,
    BMedia,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    domain: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'run',
  ],
  methods: {
    initials() {
      // return this.domain.name.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('').toUpperCase()
      return this.generateInitials(this.domain.name)
    },
    session() {
      return useCubus.getSession()
    },
    isCurrentDomain() {
      return this.domain.sid === this.session().domain.sid
    },
    onRun() {
      console.log('run')
      this.$emit('run', this.domain)
    },
    generateInitials(name) {
      let initials = ''
      const words = name.split(' ')
      for (let i = 0; i < words.length; i++) {
        const word = words[i].trim()
        if (word.length > 0) {
          const firstChar = word[0].toUpperCase()
          initials += firstChar
        }
      }
      return initials.slice(0, 2)
    },
  },
}
</script>

<style scoped>

</style>
